import { getAccountId } from './common';
import getAxios from './common/header';
import { directoryCode } from './common/directoryCode';
export default class MarketingService {
    async getMarketing() {
        const accountId = getAccountId();
        try {
            var response = await getAxios().get(
                `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v2/answer-bank?id=${accountId}&qsid=${directoryCode.MARKETING}`
            );
            return response;
        } catch (err) {
            console.error(err.stack);
            return err.response;
        }
    }

    async postMarketing(payload) {
        const accountId = getAccountId();
        try {
            var response = await getAxios().post(
                `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v2/answer-bank?id=${accountId}&qsid=${directoryCode.MARKETING}`,
                JSON.parse(JSON.stringify(payload))
            );
            return response;
        } catch (err) {
            console.error(err.stack);
            return err.response;
        }
    }
}
