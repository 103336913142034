/* global tinymce */
<template>
    <div class="p-d-flex p-jc-between">
        <div>
            <p class="profile-title">Marketing Profile</p>
            <p class="subtitle">Describe your company's strengths and capabilities for prospective Members</p>
        </div>
        <div v-if="show">
            <Button label="Cancel" class="p-button-outlined button" @click="cancel" />
            <Button label="Save" class="button" @click="saveMarketing" />
        </div>
    </div>
    <div class="profile-options">
        <div class="profile-actions">
            <Button
                label="Delete"
                icon="pi pi-trash"
                @click="deleteProfileDialog = true"
                :disabled="defaultProfileId === activeProfileObj.id"
            />
            <Button label="Copy" icon="pi pi-copy" @click="handleCopyButtonClick" />
        </div>
        <div class="profile-dropdown-wrapper">
            <p>Select Profile</p>
            <Dropdown
                v-model="profileDropdownValue"
                @change="changeActiveProfile"
                :options="profileDropdownValues"
                optionLabel="name"
                optionValue="code"
            />
        </div>
    </div>
    <div class="profile-details">
        <div>
            <p>Profile Name</p>
            <InputText
                class="input-text"
                placeholder="Profile Name"
                :disabled="defaultProfileId === activeProfileObj.id"
                v-model="activeProfileObj.name"
                @input="inputChange"
            />
            <span class="error-msg" v-if="profileNameErr">{{ profileNameErr }}</span>
        </div>
        <div>
            <p>Profile Key</p>
            <InputText
                class="input-text lowercase"
                placeholder="Profile Key"
                :disabled="defaultProfileId === activeProfileObj.id"
                v-model="activeProfileObj.profile_key"
                @input="inputChange"
            />
            <span class="error-msg" v-if="profileKeyErr">{{ profileKeyErr }}</span>
        </div>
    </div>
    <div class="p-d-flex p-flex-column p-mx-3 p-my-2">
        <p>
            Summary <span class="error-msg" v-if="summaryErr">Maximum word count exceeded. Limit {{ summaryLength }} words.</span>
        </p>
        <Textarea
            class="summary"
            v-model="activeProfileObj.summary"
            name="summary"
            placeholder='Summarize your firm in 1 or 2 sentences. This will be used on the small "card" when customers see a selection of candidate Partners. (Maximum word: 80)'
            rows="5"
            cols="30"
            :autoResize="true"
            @input="inputChange()"
        />
        <div class="summary-length">
            <p v-if="activeProfileObj.summary">{{ activeProfileObj.summary.split(' ').length - 1 }} WORDS</p>
            <p v-else-if="!activeProfileObj.summary">0 WORDS</p>
        </div>
    </div>
    <div class="p-d-flex">
        <div class="left-container p-mx-3 p-my-2">
            <p>
                Why choose us?
                <span class="error-msg" v-if="chooseUsErr">Maximum word count exceeded. Limit {{ chooseUsLength }} words.</span>
            </p>
            <editor
                id="chooseUs"
                :api-key="apiKey"
                :init="tinyMCEOptionsMax"
                @keydown="inputChange()"
                v-model="activeProfileObj.chooseUs"
            />
        </div>
        <div class="p-flex-column right-container p-mx-3 p-my-2">
            <div class="p-mb-4">
                <p>
                    Strong point #1
                    <span class="error-msg" v-if="point1Err">Maximum word count exceeded. Limit {{ point1Length }} words.</span>
                </p>
                <editor
                    id="strongPoint1"
                    :api-key="apiKey"
                    :init="tinyMCEOptionsMin1"
                    @keydown="inputChange()"
                    v-model="activeProfileObj.strongPoint1"
                />
            </div>
            <div class="p-mb-4">
                <p>
                    Strong point #2
                    <span class="error-msg" v-if="point2Err">Maximum word count exceeded. Limit {{ point2Length }} words.</span>
                </p>
                <editor
                    id="strongPoint2"
                    :api-key="apiKey"
                    :init="tinyMCEOptionsMin2"
                    @keydown="inputChange()"
                    v-model="activeProfileObj.strongPoint2"
                />
            </div>
            <div class="p-mb-4">
                <p>
                    Strong point #3
                    <span class="error-msg" v-if="point3Err">Maximum word count exceeded. Limit {{ point3Length }} words.</span>
                </p>
                <editor
                    id="strongPoint3"
                    :api-key="apiKey"
                    :init="tinyMCEOptionsMin3"
                    @keydown="inputChange()"
                    v-model="activeProfileObj.strongPoint3"
                />
            </div>
        </div>
    </div>
    <Dialog v-model:visible="deleteProfileDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 1.75rem; margin-right: 0.5rem" />
            <span
                >Are you sure you want to delete <b>{{ activeProfileObj.name }}</b> profile?</span
            >
        </div>

        <template #footer>
            <Button label="No" class="p-button p-component p-button-outlined button" @click="deleteProfileDialog = false" />
            <Button label="Yes" class="p-button p-component button" @click="deleteProfile" />
        </template>
    </Dialog>
    <Dialog v-model:visible="discardProfileDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 1.75rem; margin-right: 0.5rem" />
            <span
                >You have made some changes to <b>{{ activeProfileObj.name }}</b> profile. Please save or discard to
                proceed.</span
            >
        </div>

        <template #footer>
            <Button label="Discard" class="p-button p-component p-button-outlined button" @click="discardChanges" />
            <Button label="Save" class="p-button p-component button" @click="saveMarketing" />
        </template>
    </Dialog>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { getAccount, getAccountId } from '../../service/common';
import MarketingService from '../../service/MarketingService';
import { onMounted } from '@vue/runtime-core';

const emptyDefaultProfile = {
    id: 'default',
    name: 'Default',
    profile_key: 'default',

    summary: '',
    chooseUs: '',
    strongPoint1: '',
    strongPoint2: '',
    strongPoint3: '',
};

const generateRandomString = () => Math.random().toString(36).slice(2);

export default {
    data() {
        return {
            show: false,
            defaultProfileId: 'default',
            activeProfileObj: { ...emptyDefaultProfile },
            marketingProfileDataObj: {
                Default: { ...emptyDefaultProfile },
            },
            profileDropdownValues: [{ name: 'Default', code: 'default' }],
            profileDropdownValue: 'default',
            deleteProfileDialog: false,
            discardProfileDialog: false,
            isAdmin: false,
            partnerId: null,
            apiKey: process.env.VUE_APP_TINY_KEY,
            chooseUs: '',
            summaryLength: 80,
            chooseUsLength: 500,
            point1Length: 150,
            point2Length: 150,
            point3Length: 150,
            errorMsgBase: 'Maximum word count exceeded for ',
            errorArr: [],
            generatedErrorMsg: '',
            profileNameErr: '',
            profileKeyErr: '',
            summaryErr: false,
            chooseUsErr: false,
            point1Err: false,
            point2Err: false,
            point3Err: false,
            fieldId: null,

            tinyMCEOptionsMax: {
                formats: {
                    h1: { block: 'h1', styles: { fontSize: '1.7em', color: '#333333' } },
                    h2: { block: 'h2', styles: { fontSize: '1.5em', color: '#333333' } },
                    h3: { block: 'h3', styles: { fontSize: '1.3em', color: '#333333' } },
                },
                branding: false,
                menubar: false,
                min_height: 1000,
                resize: false,
                placeholder:
                    'Answer the question "Why should you choose us" in a few paragraphs, using (as you prefer) lists, bolding, italics, and more (Maximum word: 500)',
                toolbar: 'bold italic underline bullist numlist link h1 h2 h3 | removeformat',
                plugins: ['lists', 'link', 'wordcount', 'autoresize', 'paste'],
                paste_as_text: false,
                paste_block_drop: false,
                paste_data_images: false,
                link_default_target: '_blank',
                font_size_formats: '10pt 12pt 14pt 16pt 18pt 24pt',
                link_assume_external_targets: 'https',
                content_style:
                    'p{font-size: 1em}' +
                    '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {font-size: 14px;color:rgb(46 55 64 / 80%)}',
                selector: 'textarea',
                contextmenu: 'undo copy cut paste | selectall bold italic underline | removeformat',
            },
            tinyMCEOptionsMin1: {
                formats: {
                    h1: { block: 'h1', styles: { fontSize: '1.7em', color: '#333333' } },
                    h2: { block: 'h2', styles: { fontSize: '1.5em', color: '#333333' } },
                    h3: { block: 'h3', styles: { fontSize: '1.3em', color: '#333333' } },
                },
                branding: false,
                menubar: false,
                min_height: 300,
                resize: false,
                placeholder:
                    'Write a few paragraphs describing a FIRST strong point about your firm. Use bolding, italics, links, lists, etc. (as appropriate) to emphasize your message. (Maximum word: 150)',
                toolbar: 'bold italic underline bullist numlist link h1 h2 h3 | removeformat',
                plugins: ['lists', 'link', 'wordcount', 'autoresize', 'paste'],
                paste_as_text: false,
                paste_block_drop: false,
                paste_data_images: false,
                link_default_target: '_blank',
                font_size_formats: '10pt 12pt 14pt 16pt 18pt 24pt',
                link_assume_external_targets: 'https',
                content_style:
                    'p{font-size: 1em}' +
                    '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {font-size: 14px;color:rgb(46 55 64 / 80%)}',
                selector: 'textarea',
                contextmenu: 'undo copy cut paste | selectall bold italic underline | removeformat',
            },
            tinyMCEOptionsMin2: {
                formats: {
                    h1: { block: 'h1', styles: { fontSize: '1.7em', color: '#333333' } },
                    h2: { block: 'h2', styles: { fontSize: '1.5em', color: '#333333' } },
                    h3: { block: 'h3', styles: { fontSize: '1.3em', color: '#333333' } },
                },
                branding: false,
                menubar: false,
                min_height: 300,
                resize: false,
                placeholder:
                    'Write a few paragraphs describing a SECOND strong point about your firm. Use bolding, italics, links, lists, etc. (as appropriate) to emphasize your message. (Maximum word: 150)',
                toolbar: 'bold italic underline bullist numlist link h1 h2 h3 | removeformat',
                plugins: ['lists', 'link', 'wordcount', 'autoresize', 'paste'],
                paste_as_text: false,
                paste_block_drop: false,
                paste_data_images: false,
                link_default_target: '_blank',
                font_size_formats: '10pt 12pt 14pt 16pt 18pt 24pt',
                link_assume_external_targets: 'https',
                content_style:
                    'p{font-size: 1em}' +
                    '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {font-size: 14px;color:rgb(46 55 64 / 80%)}',
                selector: 'textarea',
                contextmenu: 'undo copy cut paste | selectall bold italic underline | removeformat',
            },
            tinyMCEOptionsMin3: {
                formats: {
                    h1: { block: 'h1', styles: { fontSize: '1.7em', color: '#333333' } },
                    h2: { block: 'h2', styles: { fontSize: '1.5em', color: '#333333' } },
                    h3: { block: 'h3', styles: { fontSize: '1.3em', color: '#333333' } },
                },
                branding: false,
                menubar: false,
                min_height: 300,
                resize: false,
                placeholder:
                    'Write a few paragraphs describing a THIRD strong point about your firm. Use bolding, italics, links, lists, etc. (as appropriate) to emphasize your message. (Maximum word: 150)',
                toolbar: 'bold italic underline bullist numlist link h1 h2 h3 | removeformat',
                plugins: ['lists', 'link', 'wordcount', 'autoresize', 'paste'],
                paste_as_text: false,
                paste_block_drop: false,
                paste_data_images: false,
                link_default_target: '_blank',
                font_size_formats: '10pt 12pt 14pt 16pt 18pt 24pt',
                link_assume_external_targets: 'https',
                content_style:
                    'p{font-size: 1em}' +
                    '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {font-size: 14px;color:rgb(46 55 64 / 80%)}',
                selector: 'textarea',
                contextmenu: 'undo copy cut paste | selectall bold italic underline | removeformat',
            },
        };
    },
    components: {
        editor: Editor,
    },
    created() {
        this.marketingService = new MarketingService();
        onMounted(() => {
            this.getMarketing();
            this.isAdmin = getAccount();
        });
    },
    watch: {
        $route() {
            this.partnerId = getAccountId();
            this.getMarketing();
        },
        partnerId() {
            this.getMarketing();
        },
    },
    methods: {
        inputChange() {
            this.show = true;
            this.profileDropdownValues = this.profileDropdownValues.map((el) => {
                if (el.code !== this.activeProfileObj.id) return el;
                return { ...el, name: this.activeProfileObj.name };
            });
            this.handleSummary();
            this.handleChooseUs();
            this.handlePoint1();
            this.handlePoint2();
            this.handlePoint3();
        },
        handleSummary() {
            this.summaryCheck();
        },
        handleChooseUs() {
            if (typeof tinyMCE != 'undefined') {
                if (tinymce.get('chooseUs').isDirty()) this.show = true;
                if (this.wordcount('chooseUs') > this.chooseUsLength) {
                    this.chooseUsErr = true;
                } else this.chooseUsErr = false;
            }
        },
        handlePoint1() {
            if (typeof tinyMCE != 'undefined') {
                if (tinymce.get('strongPoint1').isDirty()) this.show = true;
                if (this.wordcount('strongPoint1') > this.point1Length) {
                    this.point1Err = true;
                } else this.point1Err = false;
            }
        },
        handlePoint2() {
            if (typeof tinyMCE != 'undefined') {
                if (tinymce.get('strongPoint2').isDirty()) this.show = true;
                if (this.wordcount('strongPoint2') > this.point2Length) {
                    this.point2Err = true;
                } else this.point2Err = false;
            }
        },
        handlePoint3() {
            if (typeof tinyMCE != 'undefined') {
                if (tinymce.get('strongPoint3').isDirty()) this.show = true;
                if (this.wordcount('strongPoint3') > this.point3Length) {
                    this.point3Err = true;
                } else this.point3Err = false;
            }
        },
        editorDirty(val) {
            tinymce.get('chooseUs').setDirty(val);
            tinymce.get('strongPoint1').setDirty(val);
            tinymce.get('strongPoint2').setDirty(val);
            tinymce.get('strongPoint3').setDirty(val);
        },
        setActiveProfile(id) {
            this.activeProfileObj = { ...this.marketingProfileDataObj[id] };
        },
        changeActiveProfile() {
            if (this.show) {
                this.discardProfileDialog = true;
                this.profileDropdownValue = this.activeProfileObj.id;
                return;
            }
            this.setActiveProfile(this.profileDropdownValue);
        },
        discardChanges() {
            let activeProfileObj = { ...this.marketingProfileDataObj[this.activeProfileObj.id] };
            this.activeProfileObj = activeProfileObj;
            this.$toast.add({
                severity: 'success',
                summary: 'Discarded the changes successfully',
                life: 3000,
            });
            this.discardProfileDialog = false;
            this.show = false;

            this.profileNameErr = '';
            this.profileKeyErr = '';
            this.summaryErr = false;
            this.chooseUsErr = false;
            this.point1Err = false;
            this.point2Err = false;
            this.point3Err = false;
            this.editorDirty(false);

            this.profileDropdownValues = this.profileDropdownValues.map((el) => {
                if (el.code !== activeProfileObj.id) return el;
                return { ...el, name: activeProfileObj.name };
            });
        },
        copyProfile() {
            let profileToCopy = { ...this.marketingProfileDataObj[this.activeProfileObj.id] };

            let id = generateRandomString();
            let newProfileName = profileToCopy.name + ' (copy)';

            const existingProfileNames = new Set(Object.values(this.marketingProfileDataObj).map((profile) => profile.name));

            function getNewProfileName(existingProfileNames, profileName) {
                if (!existingProfileNames.has(profileName)) return profileName;

                profileName = profileName.replaceAll(/\(copy\)\s\((\d+)\)$/g, (a, b) => {
                    return `(copy) (${Number(b) + 1})`;
                });

                if (profileName.endsWith('(copy)')) {
                    return getNewProfileName(existingProfileNames, profileName + ' (1)');
                }
                return getNewProfileName(existingProfileNames, profileName);
            }

            newProfileName = getNewProfileName(existingProfileNames, newProfileName);

            let newProfileData = {
                ...profileToCopy,
                name: newProfileName,
                profile_key: '',
                id,
            };
            this.marketingProfileDataObj[newProfileData.id] = { ...newProfileData };
            this.activeProfileObj = { ...newProfileData };

            this.profileDropdownValues.push({ name: newProfileName, code: id });
            this.profileDropdownValue = id;
        },
        deleteProfile() {
            let isDefaultProfile = this.activeProfileObj.id === this.defaultProfileId;
            if (isDefaultProfile) {
                let errorMsg = 'Default profile cannot be deleted';
                this.$toast.add({
                    severity: 'error',
                    summary: errorMsg,
                    life: 3000,
                });
                this.deleteProfileDialog = false;
                return;
            }
            let profileToDelete = this.activeProfileObj.id;

            this.setActiveProfile(this.defaultProfileId);
            this.profileDropdownValue = this.defaultProfileId;

            this.profileDropdownValues = this.profileDropdownValues.filter((x) => {
                return x.code !== profileToDelete;
            });
            delete this.marketingProfileDataObj[profileToDelete];

            this.submitMarketing(false);
            this.deleteProfileDialog = false;
        },
        handleCopyButtonClick() {
            if (this.show) this.discardProfileDialog = true;
            else {
                this.copyProfile();
            }
        },
        cancel() {
            this.discardChanges();
            this.editorDirty(false);
            this.show = false;
        },
        wordcount(val) {
            return tinymce.get(val).plugins.wordcount?.body.getWordCount();
        },
        summaryCheck() {
            if (this.activeProfileObj.summary) {
                if (this.activeProfileObj.summary.split(' ').length - 1 <= this.summaryLength) {
                    this.summaryErr = false;
                    return true;
                } else {
                    this.summaryErr = true;
                    return false;
                }
            } else {
                this.summaryErr = false;
                return true;
            }
        },
        errorMsgGenerator() {
            let errorString = this.errorArr.join(', ');
            return (this.generatedErrorMsg = this.errorMsgBase + errorString);
        },
        saveMarketing() {
            this.errorArr = [];
            this.generatedErrorMsg = '';

            if (this.activeProfileObj.profile_key === '') {
                let errorMsg = 'Profile Key cannot be empty';
                this.profileKeyErr = errorMsg;
                this.$toast.add({
                    severity: 'error',
                    summary: errorMsg,
                    life: 3000,
                });
                return;
            }

            if (!/^[a-z_]+$/.test(this.activeProfileObj.profile_key)) {
                let errorMsg = 'Profile Key can have only lowercase and _';
                this.profileKeyErr = errorMsg;
                this.$toast.add({
                    severity: 'error',
                    summary: errorMsg,
                    life: 3000,
                });
                return;
            }

            for (let profile of Object.values(this.marketingProfileDataObj)) {
                if (profile.id === this.activeProfileObj.id) continue;

                if (this.activeProfileObj.name === profile.name) {
                    let errorMsg = 'Profile already exists with this Profile Name';
                    this.profileNameErr = errorMsg;
                    this.$toast.add({
                        severity: 'error',
                        summary: errorMsg,
                        life: 3000,
                    });
                    return;
                }
                if (this.activeProfileObj.profile_key === profile.profile_key) {
                    let errorMsg = 'Profile already exists with this Profile Key';
                    this.profileKeyErr = errorMsg;
                    this.$toast.add({
                        severity: 'error',
                        summary: errorMsg,
                        life: 3000,
                    });
                    return;
                }
            }

            if (!this.summaryCheck()) {
                this.errorArr.push('Summary');
            }
            if (this.wordcount('chooseUs') > this.chooseUsLength) {
                this.errorArr.push('Why choose us?');
            }
            if (this.wordcount('strongPoint1') > this.point1Length) {
                this.errorArr.push('Strong point #1');
            }
            if (this.wordcount('strongPoint2') > this.point2Length) {
                this.errorArr.push('Strong point #2');
            }
            if (this.wordcount('strongPoint3') > this.point3Length) {
                this.errorArr.push('Strong point #3');
            }
            if (this.errorArr.length === 0) {
                this.submitMarketing();
            } else {
                this.errorMsgGenerator();
                this.$toast.add({
                    severity: 'error',
                    summary: this.generatedErrorMsg,
                    life: 3000,
                });
            }
        },
        async getMarketing() {
            var data = await this.marketingService.getMarketing();
            if (data.status !== 200) {
                let id = generateRandomString();
                this.activeProfileObj = { ...emptyDefaultProfile, id };
                this.marketingProfileDataObj = {
                    Default: { ...emptyDefaultProfile, id },
                };
                this.profileDropdownValue = id;
                return;
            }

            let marketingProfileData = data.data.data.data[0].data[0].json_value || [];
            this.fieldId = data.data.data.data[0].data[0].id;

            let profileDropdownValues = [];
            if (marketingProfileData.length) {
                let marketingProfileDataObj = {};
                marketingProfileData.forEach((el) => {
                    let id = generateRandomString();
                    profileDropdownValues.push({
                        name: el.name,
                        code: id,
                    });
                    marketingProfileDataObj[id] = { ...el, id };

                    if (el.name === 'Default') {
                        this.activeProfileObj = { ...el, id };
                        this.defaultProfileId = id;
                        this.profileDropdownValue = id;
                    }
                });

                this.marketingProfileDataObj = marketingProfileDataObj;
                this.profileDropdownValues = profileDropdownValues;
            } else {
                let id = generateRandomString();
                this.activeProfileObj = { ...emptyDefaultProfile, id };
                this.defaultProfileId = id;
                this.marketingProfileDataObj = {
                    [id]: { ...emptyDefaultProfile, id },
                };
                profileDropdownValues.push({
                    name: emptyDefaultProfile.name,
                    code: id,
                });
                this.profileDropdownValues = profileDropdownValues;
                this.profileDropdownValue = id;
            }
        },
        sanitizeHtml(value) {
            if (!tinymce.get(value)?.getContent()) return null;

            let rawValue = tinymce.get(value).getContent({ format: 'rawContent' });

            // removing empty spaces before content
            let rawValueArr = rawValue.split(/\r?\n/).filter((element) => element);
            let dupArr = [...rawValueArr];
            for (let i = 0; i < rawValueArr.length; i++) {
                if (/^<p>(&nbsp; ?)*<\/p>$/.test(rawValueArr[i])) {
                    dupArr.shift();
                } else break;
            }

            // removing empty spaces after content
            let revArr = dupArr.reverse();
            let dupRevArr = [...revArr];
            for (let i = 0; i < revArr.length; i++) {
                if (/^<p>(&nbsp; ?)*<\/p>$/.test(revArr[i])) {
                    dupRevArr.shift();
                } else break;
            }
            return dupRevArr.reverse().join('\n');
        },
        sanitizeActiveProfileObj() {
            let summary = this.activeProfileObj.summary;
            this.activeProfileObj.summary = summary ? summary.toString().trim() : null;

            const fieldsToSanitizeWithHtml = ['chooseUs', 'strongPoint1', 'strongPoint2', 'strongPoint3'];

            fieldsToSanitizeWithHtml.forEach((field) => {
                this.activeProfileObj[field] = this.sanitizeHtml(field);
            });
        },
        submitMarketing(updateEditorValues = true) {
            this.profileKeyErr = '';
            this.profileNameErr = '';
            if (updateEditorValues) this.sanitizeActiveProfileObj();

            let activeProfileId = this.activeProfileObj.id;
            this.marketingProfileDataObj[activeProfileId] = { ...this.activeProfileObj };

            let marketingProfileData = Object.values({ ...this.marketingProfileDataObj });

            let payloadData = {
                [this.fieldId]: marketingProfileData.map((el) => {
                    let copyOfEl = { ...el };
                    delete copyOfEl.id;
                    return copyOfEl;
                }),
            };
            this.marketingService.postMarketing(payloadData).then((res) => {
                if (res.status === 200) {
                    this.editorDirty(false);
                    this.show = false;
                    this.$toast.add({ severity: 'success', summary: res.data.data, life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while saving data', life: 3000 });
                }
            });
            this.discardProfileDialog = false;
        },
    },
};
</script>

<style scoped lang="scss">
.profile-title {
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
}

p {
    font-size: 1.5rem;
    font-weight: 400;
}

.left-container {
    width: 50%;
    min-width: 400px;
}

.right-container {
    width: 50%;
    min-width: 400px;
}

.error-msg {
    color: red;
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
}

::v-deep(.p-button) {
    background: #00796b !important;
    width: 8rem !important;
    border: 2px solid #00796b !important;
}

::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
    margin-right: 1.25rem;
}

.subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 12px 0;
    margin-left: 1rem;
}

.summary-length {
    width: 100%;
    height: 1.5rem;
    border: 1px solid #ccc;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    p {
        text-align: right !important;
        color: rgba(34, 47, 62, 0.7);
        font-size: 0.85rem;
        margin-right: 1ch;
        align-content: center;
        justify-content: center;
        margin-top: 2px;
    }
}

.summary {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.profile-details {
    display: grid;
    grid-gap: 20px;
    margin: 1rem;
}

.profile-details div {
    display: grid;
    justify-items: start;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: minmax(125px, 200px) auto;
}

.profile-details p {
    margin: 0;
}

.p-button {
    width: auto !important;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.profile-options {
    display: grid;
    grid-auto-flow: column;
    justify-content: right;
    grid-gap: 40px;
}

.profile-actions {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
}

.profile-dropdown-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    align-items: center;
}

.profile-dropdown-wrapper p {
    margin: 0;
}

.profile-dropdown-wrapper div {
    min-width: 200px;
}

.profile-actions button:disabled {
    cursor: not-allowed !important;
    pointer-events: all;
}

.lowercase {
    text-transform: lowercase;
}
</style>
